import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-record',
  templateUrl: './skeleton-record.component.html',
  styleUrls: ['./skeleton-record.component.scss'],
})
export class SkeletonRecordComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
