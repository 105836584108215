import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  token = 'api_token';
  public count = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  findAll(): Observable<any> {
    return this.http.get<any>(`${apiUrl}notifications`, this.httpOptions);
  }

  clearUnviewed(): Observable<any> {
    return this.http.post<any>(`${apiUrl}notifications`, this.httpOptions);
  }

  findUnviewed(): Observable<any> {
    return this.http.get<any>(
      `${apiUrl}notifications/unviewed`,
      this.httpOptions
    );
  }

  deleteAll(): Observable<any> {
    return this.http.delete<any>(`${apiUrl}notifications`, this.httpOptions);
  }

  public setCountNotifications(number: number) {
    this.count = number;
  }
}
