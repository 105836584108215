import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class EnableService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor( private http: HttpClient ) { }
  enable(model:string,id:BigInteger){
    return this.http.get<any>(apiUrl+model+ "-enable/"+id, this.httpOptions);
  }
}
