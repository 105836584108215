import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User, LoginData } from '../../interfaces/interfaces';
import { throwError, Observable } from 'rxjs';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class UploadsService {

  constructor(private fileTransfer:FileTransfer, private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      /*'Content-Type': 'application/json',*/
      'Accept': "application/json",
      /*'Access-Control-Allow-Origin':'*',*/
      'enctype': 'multipart/form-data;',
    }),
    reportProgress:true,
  };

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${apiUrl}attachments`, formData, this.httpOptions );

    return this.http.request(req);
  }

 

  public uploadImage(image: File,id:string): Observable<any> {
    const formData = new FormData();

    formData.append('file', image);
    console.log(image);

    return this.http.post(apiUrl+'person-file?person_id='+id, formData,this.httpOptions);
  }

  public uploadAttatch(file: File): Observable<any> {
    const formData = new FormData();

    formData.append('file', file);
    console.log(file);

    return this.http.post(apiUrl+'attachments', formData,this.httpOptions);
  }

  public uploadAttatchAsync(file: File): Observable<any> {
    const formData = new FormData();
    
    formData.append('file', file);
    console.log(file);

    return this.http.post<any>(apiUrl+'attachments', formData,this.httpOptions);
      
  };

  /*public uploadAttatchAsync(file: File) {
    const formData = new FormData();
    
    formData.append('file', file);
    console.log(file);

    return new Promise( resolve => {
      this.http.post<any>(apiUrl+'attachments', formData,this.httpOptions).subscribe((res)=>{
        const idAttach = res.id
        resolve(idAttach);
      },(err)=>{
        resolve(err);
      
      });
      
    });

  }*/

  getMime(att){
    let url = att.split('https://monandtots.s3.amazonaws.com/attachments/')
    let mime = url[1].split('.')
    console.log(mime[1]);
    if(mime[1]=='gif'|| mime[1]=='png' || mime[1]=='jpeg' || mime[1]=='webp'){
      return 'image';
    }else{
      return 'other'
    }
  }


  

}
