import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-groups',
  templateUrl: './popover-groups.component.html',
  styleUrls: ['./popover-groups.component.scss'],
})
export class PopoverGroupsComponent implements OnInit {

  data;
  constructor(private navParams: NavParams) { }

  ngOnInit() {
   
    console.log(this.navParams)
    this.data = this.navParams.data.list;

    console.log( this.navParams.data.list);
  }

}
 