import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class AccessLogService {

  httpOptions = {
	  headers: new HttpHeaders({
	    'Content-Type': 'application/json',
     }),
     params:new HttpParams()
	};
  constructor( private http:HttpClient ) { }

  sendQr(code: any): Observable<any> {
	  return this.http.post<any>(apiUrl+ "qrscan", code, this.httpOptions)
	}

  saveLog(log: any): Observable<any> {
	return this.http.post<any>(apiUrl+ "accesslog", log, this.httpOptions)
  }

  index(): Observable<any> {
    return this.http.get<any>(apiUrl+'accesslog',this.httpOptions);
  }

  logPresence(branchId?): Observable<any> {
    console.log('branch_id',branchId);
    if(branchId!=undefined){
    this.httpOptions.params = this.httpOptions.params.set('branch_office_id',branchId);
    }
    return this.http.get<any>(apiUrl+'presence',this.httpOptions);
  }


  
	
}
