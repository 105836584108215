import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, MenuController } from '@ionic/angular';
import { NotificationService } from '../../services/notification/notification.service';
import { PushService } from '../../services/push.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() rol?: string;
  @Input() isTransparent?: boolean;
  @Input() isDashboard?: boolean;
  @Input() title: string;
  @Input() editLink: string;
  @Input() createLink: string;
  @Input() actionSheet = false;
  @Input() showEditLink: boolean;
  @Input() showCreateLink: boolean;
  @Input() color = 'background-color-primary';

  constructor(
    private router: Router,
    private menu: MenuController,
    private actionSheetController: ActionSheetController,
    public notificationService: NotificationService,
    private pushService: PushService
  ) {}

  ngOnInit() {
    this.notifications();
  }

  openMenu() {
    this.menu.enable(true, 'menu');
    this.menu.open('menu');
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'EGRESOS',
      cssClass: 'my-custom-class',
      subHeader: 'Elija el tipo de pago a procesar',
      buttons: [
        {
          text: 'Pago a Proveedores',
          role: 'destructive',
          icon: 'business-outline',
          handler: () =>
            this.router.navigate([
              'taps/administracion/finance/outflows/create-provider-outflow',
            ]),
        },
        {
          text: 'Pago de Nómina',
          icon: 'newspaper-outline',
          handler: () =>
            this.router.navigate([
              'taps/administracion/finance/outflows/create-payroll-outflow',
            ]),
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
  }

  public formatNumber(number: number) {
    return number <= 99 ? number : 99;
  }

  private notifications() {
    this.notificationService.findUnviewed().subscribe((res) => {
      this.notificationService.setCountNotifications(res);
    });
  }
}
