import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class GradeMenssagesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',

    }),
    params: new HttpParams()

  };

  constructor(private http: HttpClient) { }

  index(id, idEstudent): Observable<any> {
    this.httpOptions.params = this.httpOptions.params.delete('branch_office');
    this.httpOptions.params = this.httpOptions.params.delete('student_id');
    if (id != 'null') {
      this.httpOptions.params = this.httpOptions.params.append('branch_office', id);
    }
    if (idEstudent) {
      this.httpOptions.params = this.httpOptions.params.append('student_id', idEstudent);
    }
    return this.http.get<any>(apiUrl + 'grade-messages', this.httpOptions);
  }

  show(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'grade-messages/' + id, this.httpOptions);
  }

  addGradeMessage(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "grade-messages", message, this.httpOptions);
  }

  response(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "grade-responses", message, this.httpOptions);
  }

  addAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "grade-messages/attachments/" + id, attaches, this.httpOptions);
  }

  responseAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "grade-responses/attachments/" + id, attaches, this.httpOptions);
  }

}
