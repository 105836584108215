import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SchoolCycles } from 'src/app/interfaces';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class SchoolYearService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(private http: HttpClient) { }

  add(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'school-cycle', provider, this.httpOptions);
  }

  index(): Observable<SchoolCycles[]> {
    return this.http.get<SchoolCycles[]>(apiUrl + 'school-cycle', this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'school-cycle/' + id, this.httpOptions);
  }


  update(provider: any, id: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'school-cycle/' + id, provider, this.httpOptions);
  }
}
