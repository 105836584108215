import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-event',
  templateUrl: './skeleton-event.component.html',
  styleUrls: ['./skeleton-event.component.scss'],
})
export class SkeletonEventComponent implements OnInit {
  constructor() {}

  public loaded = false;

  ngOnInit() {}
}
