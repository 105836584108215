import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthorizedPeople, LoginData } from '../../interfaces/interfaces';
import { throwError, Observable } from 'rxjs';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthorizedPeopleService {

  token:string = 'api_token'   ;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor( private http: HttpClient ) { }
  
  
  index(id: any): Observable<any[]> {
    return this.http.get<any[]>(apiUrl+'authorized-person?student_id='+id,this.httpOptions);
  } 
  addStudent(student: any): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "authorized-person", student, this.httpOptions);
  }
  addAuthPeople(authPeople: any): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "authorized-person", authPeople, this.httpOptions);
  }
  
  showAddPepople(authID: any): Observable<any>{
    return this.http.get<any[]>(apiUrl+'authorized-person/'+authID,this.httpOptions);
  }
  
  editAuthPepople(authPeople: any,authID: any): Observable<any>{
    return this.http.post<any[]>(apiUrl+'authorized-person/'+authID, authPeople, this.httpOptions);
  }

  enableAuth(authID){
    return this.http
    .get<any>(apiUrl+ "authorized-people-enable/"+authID, this.httpOptions);
  }

  QRimage(data: any): Observable<any>{
    return this.http.post<any>(apiUrl+'qrimage',data,this.httpOptions);
  }


}
