import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', },),
    params: new HttpParams()
  };

  constructor(private http: HttpClient) { }

  addGroupMessage(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "group-messages", message, this.httpOptions);
  }

  index(category, idGroup, idEstudent): Observable<any> {
    this.httpOptions.params = this.httpOptions.params.delete('category');
    this.httpOptions.params = this.httpOptions.params.delete('group_id');
    this.httpOptions.params = this.httpOptions.params.delete('student_id');

    if (category != '') {
      this.httpOptions.params = this.httpOptions.params.set('category', category);
    }

    if (idGroup) {
      this.httpOptions.params = this.httpOptions.params.set('group_id', idGroup);
    }

    if (idEstudent) {
      this.httpOptions.params = this.httpOptions.params.set('student_id', idEstudent);
    }

    return this.http.get<any>(apiUrl + 'group-messages', this.httpOptions);
  }

  show(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'group-messages/' + id, this.httpOptions);
  }

  response(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "group-responses", message, this.httpOptions);
  }

  addAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "group-messages/attachments/" + id, attaches, this.httpOptions);
  }

  responseAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "group-responses/attachments/" + id, attaches, this.httpOptions);
  }

}