import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileService } from '../../services/file/file.service';
@Component({
  selector: 'preview-file',
  templateUrl: './preview-file.component.html',
  styleUrls: ['./preview-file.component.scss'],
})
export class PreviewFileComponent implements OnInit {
  constructor(public fileService: FileService, private platform: Platform) {}

  @Input() attaches: any[];
  isMobile = true;

  @ViewChild('previewIframe') previewIframe: ElementRef<HTMLIFrameElement>;

  ngOnInit() {
    this.isMobile = !this.platform.is('desktop');
  }

  public resizeIframe() {
    if (this.previewIframe?.nativeElement) {
      this.previewIframe.nativeElement.style.height =
        this.previewIframe.nativeElement.offsetWidth / 1.5 + 'px';
    }
  }
}
