import { Pipe, PipeTransform } from '@angular/core';
import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

@Pipe({
  name: 'findUrlsAndConvertThemToLinks',
})
export class FindUrlsAndConvertThemToLinksPipe implements PipeTransform {
  transform(value: string): unknown {
    return !value
      ? value
      : linkifyHtml(value, {
          rel: 'nofollow noopener noreferrer',
          target: '_blank',
        });
  }
}
