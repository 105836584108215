import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-body',
  templateUrl: './logo-body.component.html',
  styleUrls: ['./logo-body.component.scss'],
})
export class LogoBodyComponent implements OnInit {

  data = {
    img:'assets/MomsAndTotsLogo.svg',
  }
 // TODO: uddate @
  constructor() { }

  ngOnInit() {}

}
