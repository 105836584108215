import { Component } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PushService } from './services/push.service';

import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { UsersService } from './services/services.index';
import { Location } from '@angular/common';
import { ConstantsService } from './services/constants.service';
import * as Badge from '@awesome-cordova-plugins/badge/ngx';
import { NotificationService } from './services/notification/notification.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private storage: Storage,
    private location: Location,
    private platform: Platform,
    private statusBar: StatusBar,
    private pushService: PushService,
    private userService: UsersService,
    private splashScreen: SplashScreen,
    private alertController: AlertController,
    private constantsService: ConstantsService,
    public notificationService: NotificationService
  ) {
    this.initializeApp();
    this.backButtonEvent();
    this.verifySession();
    this.getCurrentPlatform();
  }

  initializeApp() {
    const badge = new Badge.Badge();
    this.platform.ready().then(async () => {
      await badge.clear();
      this.pushService.setNotificationService(this.notificationService);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      await this.pushService.initConfig();
    });
  }

  doRefresh(event) {
    setTimeout(() => {
      event.target.complete();
    }, 2000);
  }

  backButtonEvent() {
    this.platform.backButton.subscribe(() => {
      const current = window.location.pathname;
      if (current === '/taps/dashboard') {
        this.confirmClose();
      } else {
        if (current === '/' || current === '/start') {
          this.confirmClose();
        } else {
          this.location.back();
        }
      }
    });
  }

  verifySession() {
    let token = null;
    this.storage.get('api_token').then((tok) => {
      token = tok;
      if (!token) {
        token = localStorage.getItem('api_token');
      }
      this.validateCurrentAppToken(token);
    });
  }

  validateCurrentAppToken(token: string) {
    if (token != null) {
      this.userService.apiTokenValidator().subscribe({
        next: async () => {
          if (!this.platform.is('desktop')) {
            const osId = this.constantsService.getOneSignal();
            if (osId !== null && osId !== undefined) {
              (
                await this.userService.putOnesignalId(
                  this.constantsService.getUserId(),
                  { one_signal_user_id: this.constantsService.getOneSignal() }
                )
              ).subscribe({
                next: () => {
                  console.log(
                    'One signal next()',
                    this.constantsService.getOneSignal()
                  );
                },
                error: (error: any) => {
                  console.log('One signal error() JSON', JSON.stringify(error));
                  console.log(error);
                },
              });
            } else {
            }
          }

          this.router.navigate(['taps/dashboard']);
        },
        error: (error) => {
          if (error.status === 404) {
            this.clearOldUserData();
            this.router.navigate(['start']);
          }
        },
      });
    } else {
      this.clearOldUserData();
      this.router.navigate(['start']);
    }
  }

  clearOldUserData() {
    console.log('inicio el clear');
    const us = localStorage.getItem('us');
    const pass = localStorage.getItem('pass');
    const oneSignalId = localStorage.getItem('oneSignalId');
    localStorage.clear();
    if (us && pass) {
      localStorage.setItem('us', us);
      localStorage.setItem('pass', pass);
    }
    localStorage.setItem('oneSignalId', oneSignalId);
  }

  async confirmClose() {
    const alerta = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cerrar App',
      message: '¿Seguro que desea salir de la app?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {},
        },
        {
          text: 'Si',
          cssClass: 'Primary',
          handler: () => {
            navigator['app'].exitApp();
          },
        },
      ],
    });
    await alerta.present();
  }

  getCurrentPlatform() {
    if (this.platform.is('android')) {
      console.log('android platform');
    }
    if (this.platform.is('capacitor')) {
      console.log('capacitor platform');
    }
    if (this.platform.is('cordova')) {
      console.log('cordova platform');
    }
    if (this.platform.is('desktop')) {
      console.log('desktop platform');
    }
    if (this.platform.is('electron')) {
      console.log('electron platform');
    }
    if (this.platform.is('hybrid')) {
      console.log('hybrid platform');
    }
    if (this.platform.is('ios')) {
      console.log('ios platform');
    }
    if (this.platform.is('ipad')) {
      console.log('ipad platform');
    }
    if (this.platform.is('iphone')) {
      console.log('iphone platform');
    }
    if (this.platform.is('mobile')) {
      console.log('mobile platform');
    }
    if (this.platform.is('mobileweb')) {
      console.log('mobileweb platform');
    }
    if (this.platform.is('phablet')) {
      console.log('phablet platform');
    }
    if (this.platform.is('pwa')) {
      console.log('pwa platform');
    }
    if (this.platform.is('tablet')) {
      console.log('tablet platform');
    }
  }
}
