import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Student, LoginData, People } from '../../interfaces/interfaces';
import { throwError, Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class StudentsService {
  token = 'api_token';
  public students: any[];
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      enctype: 'multipart/form-data;',
    }),
  };
  constructor(private http: HttpClient) {}

  index(id, notTrashed = false): Observable<any[]> {
    let query = '';
    if (id !== 'null') {
      query = `?branch_office=${id}`;
    }

    if (notTrashed) {
      query += `${query === '' ? '?' : '&'}notTrashed=true`;
    }
    return this.http.get<any[]>(apiUrl + 'student' + query, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get(apiUrl + 'student/' + id, this.httpOptions).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateStudent(student: any, id) {
    return this.http.post<any>(
      apiUrl + 'student/' + id,
      student,
      this.httpOptions
    );
  }

  updatePeople(people: any, id) {
    return this.http.post<any>(
      apiUrl + 'people/' + id,
      people,
      this.httpOptions
    );
  }

  addStudent(student: any): Observable<any> {
    return this.http.post<any>(
      apiUrl + 'student/registration',
      student,
      this.httpOptions
    );
  }

  addPeople(student: any): Observable<any> {
    return this.http.post<any>(apiUrl + 'people', student, this.httpOptions);
  }

  addFromBrother(student: any): Observable<any> {
    return this.http.post<any>(
      apiUrl + 'student/frombrother',
      student,
      this.httpOptions
    );
  }

  enrollParents(parents: any) {
    return this.http.post<any>(
      apiUrl + 'students/enrollParents',
      parents,
      this.httpOptions
    );
  }

  enableStudent(stundetid) {
    return this.http.get<any>(
      apiUrl + 'student-enable/' + stundetid,
      this.httpOptions
    );
  }

  getAllStudents(userBranch) {
    return new Promise((resolve) => {
      this.index(userBranch).subscribe(async (res) => {
        this.students = res;
        await resolve(res);
      });
    });
  }

  studentCounters(branchOfficeId?) {
    let query;
    branchOfficeId
      ? (query = '?branch_office_id=' + branchOfficeId)
      : (query = '');

    return this.http
      .get<any>(apiUrl + 'student-counters' + query, this.httpOptions)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}
