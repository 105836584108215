import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { ImageSanitizerPipe } from './image-sanitizer.pipe';
import { FilterSudentPipe } from './filter-sudent.pipe';
import { FilterDatePipe } from './filter-date.pipe';
import { FilterRealStudentPipe } from './filter-real-student.pipe';
import { CategoryFilterPipe } from './category/category-filter.pipe';
import { PaymentMethodFilterPipe } from './payment-method/payment-method-filter.pipe';
import { ProductsFilterPipe } from './products/products-filter.pipe';
import { DiscountsFilterPipe } from './discounts/discounts-filter.pipe';
import { ScholarshipsFilterPipe } from './scholarships/scholarships-filter.pipe';
import { ScholarshipStudentsFilterPipe } from './scholarship-students/scholarship-students-filter.pipe';
import { WorkAreasFilterPipe } from './work-areas/work-areas-filter.pipe';
import { EstimatedCostsFilterPipe } from './estimated-costs/estimated-costs-filter.pipe';
import { PositionsFilterPipe } from './positions/positions-filter.pipe';
import { InactiveStudentsPipe } from './inactive-students.pipe';
import { GetRolPipe } from './getRol.pipe';
import { FindUrlsAndConvertThemToLinksPipe } from './find-urls-and-convert-them-to-links.pipe';

@NgModule({
  declarations: [
    FilterPipe,
    ImageSanitizerPipe,
    FilterSudentPipe,
    FilterDatePipe,
    FilterRealStudentPipe,
    CategoryFilterPipe,
    PaymentMethodFilterPipe,
    ProductsFilterPipe,
    DiscountsFilterPipe,
    ScholarshipsFilterPipe,
    ScholarshipStudentsFilterPipe,
    WorkAreasFilterPipe,
    EstimatedCostsFilterPipe,
    PositionsFilterPipe,
    InactiveStudentsPipe,
    GetRolPipe,
    FindUrlsAndConvertThemToLinksPipe,
  ],
  exports: [
    FilterPipe,
    ImageSanitizerPipe,
    FilterSudentPipe,
    FilterDatePipe,
    FilterRealStudentPipe,
    CategoryFilterPipe,
    PaymentMethodFilterPipe,
    ProductsFilterPipe,
    DiscountsFilterPipe,
    ScholarshipsFilterPipe,
    ScholarshipStudentsFilterPipe,
    WorkAreasFilterPipe,
    PositionsFilterPipe,
    EstimatedCostsFilterPipe,
    InactiveStudentsPipe,
    GetRolPipe,
    FindUrlsAndConvertThemToLinksPipe,
  ],
})
export class PipesModule {}
