import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ScholarshipsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  index(): Observable<any> {
    return this.http.get<any>(apiUrl + 'scholarships', this.httpOptions);
  }

  create(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + "scholarships", provider, this.httpOptions);
  }

  update(provider: any, id: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + "scholarships/" + id, provider, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'scholarships/' + id, this.httpOptions);
  }
}
