import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PayoutsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }

  add(provider: any): Observable<any> {
    console.log(provider);
    return this.http.post<any>(apiUrl + 'payouts', provider, this.httpOptions);
  }

  index(id: string): Observable<any> {
    let query = '';
    if (id !== 'null'){
      query = '?branch_office=' + id;
    }
    return this.http.get<any>(apiUrl + 'payouts' + query, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'payouts/' + id, this.httpOptions);
  }
}
