import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSudent'
})
export class FilterSudentPipe implements PipeTransform {

  transform(array: any[], text: string): any[] {

    if(text === ''){
      return array;
    }
  //  console.log(array);
    text = text.toLowerCase();
  
    return array.filter(function(item){
    
      return item.log.toLowerCase().includes(text);
  });

   

  }


}
