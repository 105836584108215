import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  constructor() {}

  userRol: any;
  idBranch: any;
  token: string;
  person: string;
  oneSignalId: string;

  setUserRol(idRole) {
    this.userRol = idRole;
    localStorage.removeItem('userRol');
    localStorage.setItem('userRol', this.userRol);
  }

  async setUserBranch(idBranch) {
    this.idBranch = idBranch || null;
    localStorage.removeItem('idBranch');
    localStorage.setItem('idBranch', this.idBranch);
  }

  setUserToken(token) {
    this.token = token;
    localStorage.removeItem('api_token');
    localStorage.setItem('api_token', this.token);
  }

  setUserPerson(person) {
    this.person = person;
    localStorage.removeItem('person');
    localStorage.setItem('person', this.person);
  }

  getUserPerson() {
    this.person = localStorage.getItem('person');
    return this.person;
  }

  async setOneSignal(oneSignalId) {
    this.oneSignalId = oneSignalId;
    localStorage.setItem('oneSignalId', oneSignalId);
    return oneSignalId;
  }

  getOneSignal() {
    return (this.oneSignalId = localStorage.getItem('oneSignalId'));
  }

  getUserRol(): string {
    this.userRol = localStorage.getItem('userRol');
    return this.userRol;
  }

  getUserBranch() {
    this.userRol = localStorage.getItem('idBranch');
    return this.userRol;
  }

  getUserToken() {
    this.userRol = localStorage.getItem('api_token');
    return this.userRol;
  }

  setUserId(id) {
    localStorage.removeItem('userID');
    localStorage.setItem('userID', id);
  }

  getUserId() {
    this.userRol = localStorage.getItem('userID');
    return this.userRol;
  }

  setUserFullName(fullName) {
    localStorage.removeItem('full_name');
    localStorage.setItem('full_name', fullName);
  }

  getUserFullName() {
    return localStorage.getItem('full_name');
  }

  deleteUserFullName() {
    localStorage.removeItem('full_name');
  }
}
