import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getRol',
})
export class GetRolPipe implements PipeTransform {
  transform(user: any): string {
    if (
      user.role.name !== 'Padre' ||
      user.person?.gender?.toLowerCase() !== 'm'
    )
      return user.role.name;

    return 'Madre';
  }
}
