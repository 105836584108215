import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Student, LoginData, People } from '../../interfaces/interfaces';
import { throwError, Observable } from 'rxjs';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class DownloadsService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'enctype': 'multipart/form-data;',
      'responseType': 'blob',
     })
  };
  constructor( private http: HttpClient ) { }

  studentReportDownload(branchOfficeId?): Observable<any>{
    let query; 
    branchOfficeId ? query = `?branch_office_id=${branchOfficeId}`: query = '';
    return this.http.get<any>(apiUrl+'report-students'+query,this.httpOptions);
  }
  authorizedPeopleReportDownload(branchOfficeId?): Observable<any>{
    let query;
    branchOfficeId ? query = `?branch_office_id=${branchOfficeId}`: query = '';
    return this.http.get<any>(apiUrl+'report-authpeople'+query,this.httpOptions);
  }
  accessLogReportDownload(form): Observable<any>{
    return this.http.post<any>(apiUrl+'report-accesslogs',form,this.httpOptions);
  }

 
}
