import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(array: any[], text: string): any[] {
   
    if(text === '' || text == undefined){
      return array;
    }else{
    
      text = text.toLowerCase();
      return array.filter(function(item){
        //console.log(JSON.stringify(item).toLowerCase());
        return JSON.stringify(item).toLowerCase().includes(text);
      });

    }
  
  }
}
