import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { DeleteService } from './delete.service';
import { LoadingService } from './loading.service';
import { EnableService } from './enable.service';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private router: Router,
    private deleteService: DeleteService,
    private enableService: EnableService,
    private loadingService: LoadingService,
    private alertController: AlertController,
  ) {}

  async presentAlert(route: any , model: any, id: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar',
      message: 'Quieres eliminar este registro?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Si',
          cssClass: 'Primary',
          handler: () => {
            this.loadingService.presentLoading();
            this.deleteService.delete(model, id).subscribe({
              next: (r) => {
                this.loadingService.loadingController.dismiss();
                this.router.navigate([route]); // REVISAR VIRTOC, PARA QUE PUEDA REDIRECCIONAR A DONDE DEBE
              },
              error: (err) => {
                this.loadingService.loadingController.dismiss();
                this.badAlert(err.error.message);
              }
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async enable(route: any , model: any, id: any) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Habilitar',
      message: 'Quieres habilitar este registro?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Si',
          cssClass: 'Primary',
          handler: () => {
            this.loadingService.presentLoading();
            this.enableService.enable(model, id).subscribe({
              next: () => {
                this.loadingService.loadingController.dismiss();
                this.router.navigate([route]);
              },
              error: (err) => {
                this.loadingService.loadingController.dismiss();
                this.badAlert(err.error.message);
              }
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async successAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Registro Completado',
      subHeader: '',
      message: '',
      buttons: ['OK']
    });
    await alert.present();
  }

  async messageAlert(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Solicitud procesada con éxito',
      subHeader: '',
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async editSuccessAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Registro Editado',
      subHeader: '',
      message: '',
      buttons: ['OK']
    });

    await alert.present();
  }

  async badAlert(err) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error en el registro ' + err,
      subHeader: '',
      message: '',
      buttons: ['OK']
    });

    await alert.present();
  }

  async logoutConfirm( processNextHandler ){
    console.log('evento');
    const alert = await this.alertController.create({

      cssClass: 'my-custom-class',
      header: 'Cerrar Sesión',
      message: '¿Seguro que desea salir de la app?',
      buttons: [
        {
          text: 'No', role: 'cancel', cssClass: 'secondary', handler: (blah) => {}
        }, {
          text: 'Si',
          cssClass: 'Primary',
          handler: () => {
            localStorage.clear();
            /*this.router.navigate['/start'];*/
            processNextHandler();
          }
        }
      ]
    });
  }

  async errorAlert(error: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: '¡Error!',
      subHeader: '',
      message: error,
      buttons: ['OK']
    });

    await alert.present();
  }

  async logoutConfirmPwa(event?) {
    console.log('evento');
    const alert = await this.alertController.create({

      cssClass: 'my-custom-class',
      header: 'Cerrar Sesión',
      message: '¿Seguro que desea salir de la app?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
           if (event){
             event.preventDefault();
           }
          }
        }, {
          text: 'Si',
          cssClass: 'Primary',
          handler: () => localStorage.clear()
        }
      ]
    });
  }

  async connectionErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error en conexión',
      subHeader: '',
      message: 'Se produjo un error en la conexión, por favor intenta nuevamente',
      buttons: ['OK']
    });

    await alert.present();
  }

}
