import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataPayComponent } from '../data-pay/data-pay.component';

@Component({
  selector: 'app-card-way-to-pay',
  templateUrl: './card-way-to-pay.component.html',
  styleUrls: ['./card-way-to-pay.component.scss'],
})
export class CardWayToPayComponent implements OnInit {
  @Input() outflow: any;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async presentModal() {
    const modal = await this.modalController.create({
      component: DataPayComponent,
      cssClass: 'my-custom-class',
      componentProps: { outflow: this.outflow, notConsultation: true },
    });
    return await modal.present();
  }
}
