import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HoursService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor(private http: HttpClient) { }

   
  index(): Observable<any[]> {
    return this.http.get<any[]>(apiUrl+'schedules',this.httpOptions);
  } 
  show(id): Observable<any[]> {
    return this.http.get<any[]>(apiUrl+'schedules/'+id,this.httpOptions);
  } 

  add(student: any): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "schedules", student, this.httpOptions);
  }

  update(student: any,id): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "schedules/"+id, student, this.httpOptions);
  }
}
