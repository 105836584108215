import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';



const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CategoryMessagesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  index(): Observable<any> {
    return this.http.get<any>(apiUrl + 'message-categories', this.httpOptions);
  }
}
