import { Pipe, PipeTransform } from '@angular/core';
import { Student } from '../interfaces/interfaces';

@Pipe({
  name: 'inactiveStudents'
})
export class InactiveStudentsPipe implements PipeTransform {

  transform(students: any[]): any[] {
    if (students?.length > 0) {
      const allStudents: any[] = students.filter((s: any) => s.deleted_at === null);
      return allStudents;
    }
  }

}
