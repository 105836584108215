import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoBodyComponent } from './logo-body/logo-body.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { PopoverComponent } from './popover/popover.component';
import { PopoverGroupsComponent } from './popover-groups/popover-groups.component';
import { MessageGalleryComponent } from './message-gallery/message-gallery.component';
import { PipesModule } from '../pipes/pipes.module';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { BackendErrorComponent } from './backend-error/backend-error.component';
import { SkeletonRecordComponent } from './skeleton-record/skeleton-record.component';
import { CardWayToPayComponent } from './card-way-to-pay/card-way-to-pay.component';
import { DataPayComponent } from './data-pay/data-pay.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { SkeletonEventComponent } from './skeletons/skeleton-event/skeleton-event.component';
import { PreviewFileComponent } from './preview-file/preview-file.component';

@NgModule({
  declarations: [
    LogoBodyComponent,
    HeaderComponent,
    BarChartComponent,
    DoughnutChartComponent,
    LineChartComponent,
    LeftMenuComponent,
    PopoverComponent,
    PopoverGroupsComponent,
    MessageGalleryComponent,
    ValidationMessagesComponent,
    UploadFilesComponent,
    BackendErrorComponent,
    SkeletonRecordComponent,
    CardWayToPayComponent,
    DataPayComponent,
    DateRangeComponent,
    SkeletonEventComponent,
    PreviewFileComponent,
  ],
  exports: [
    LogoBodyComponent,
    HeaderComponent,
    BarChartComponent,
    DoughnutChartComponent,
    LineChartComponent,
    LeftMenuComponent,
    PopoverComponent,
    PopoverGroupsComponent,
    MessageGalleryComponent,
    ValidationMessagesComponent,
    UploadFilesComponent,
    BackendErrorComponent,
    SkeletonRecordComponent,
    CardWayToPayComponent,
    DataPayComponent,
    DateRangeComponent,
    SkeletonEventComponent,
    PreviewFileComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    PipesModule,
    ReactiveFormsModule,
  ],
})
export class ComponentsModule {}
