import { Injectable, EventEmitter } from '@angular/core';
import { OneSignalPlugin } from 'onesignal-cordova-plugin';
import { Router } from '@angular/router';
import { ConstantsService } from './constants.service';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification/notification.service';
import * as Badge from '@ionic-native/badge/ngx';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  userId: string;
  pushListener = new EventEmitter<any>();
  public badge = new Badge.Badge();
  public notificationService: NotificationService;
  constructor(
    private oneSignal: OneSignalPlugin,
    private router: Router,
    private constantsService: ConstantsService
  ) {}

  public setNotificationService(notificationService: NotificationService) {
    this.notificationService = notificationService;
  }

  async initConfig() {
    try {
      this.oneSignal.setAppId(environment.oneSignalAppId);
      /* this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.Notification
      ); */

      this.oneSignal.setNotificationWillShowInForegroundHandler((event) => {
        console.log('notificación recibida', event, JSON.stringify(event));
        this.notificationReceived(event.getNotification());
      });

      this.oneSignal.promptForPushNotificationsWithUserResponse(function (
        accepted
      ) {
        console.log('User accepted notifications: ' + accepted);
      });

      this.oneSignal.setNotificationOpenedHandler(async (noti) => {
        console.log('Notificación abierta', noti, JSON.stringify(noti));
        await this.notificationOpen(noti.notification);
      });

      // Obtener ID del suscriptor
      this.oneSignal.getDeviceState(async (device) => {
        console.log('this device', device, JSON.stringify(device));
        this.userId = device.userId;
        console.log('ONE SIGNAL ID', this.userId);
        await this.constantsService.setOneSignal(this.userId);
      });
    } catch (error) {
      console.log('error en initOneSignal', error, JSON.stringify(error));
    }
  }

  async notificationOpen(noti: any) {
    await this.notificationReceived(noti);
    const addtionalData = noti.additionalData;
    const route =
      '/taps/communications/' +
      addtionalData.notification_type +
      '/detail/' +
      addtionalData.id;
    this.router.navigate([route]);
  }

  async notificationReceived(notify: any) {
    this.badge.get().then(async (value) => {
      console.log('el get ', value);
      await this.badge.set(value + 1);
    });
    this.notificationService.setCountNotifications(
      this.notificationService.count + 1
    );

    this.pushListener.emit(notify);
  }

  async getUserIdOneSignal() {
    console.log('Cargando userId');
    // Obtener ID del suscriptor
    const info = this.oneSignal.getDeviceState((res) => {
      this.userId = res.userId;
    });
    return this.userId;
  }
}
