import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class GradesService {
  token:string = 'api_token'   ;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor( private http: HttpClient ) { }

  index(id): Observable<any> {
    let query='';
    if(id!='null'){
      query='?branch_office='+id;
      
    }
    return this.http.get<any>(apiUrl+'grade'+query,this.httpOptions);
  }

  show(gradeId): Observable<any> {
    return this.http.get<any>(apiUrl+'grade/'+gradeId,this.httpOptions);
  }
  addStudent(grade: any): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "grade", grade, this.httpOptions);
  }

  updateGrade(grade: any,id): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "grade/"+id, grade, this.httpOptions);
  }
}
