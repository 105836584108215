import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BranchOffice } from 'src/app/interfaces';
import { TypeMessages } from 'src/app/interfaces/interfaces';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  token: string = 'api_token';
  public typeMessage: TypeMessages[] = [];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      
    }),
  };
  constructor(private http: HttpClient) {}

  public getTypeNotifications(): Observable<TypeMessages[]> {
    return this.http.get<TypeMessages[]>(
      `${apiUrl}messages/get/types`,
      this.httpOptions
    );
  }

  public send(form: any): Observable<any> {
    return this.http.post<any>(
      `${apiUrl}send/messages`,
      form,
      {
        headers: {
          'enctype': 'multipart/form-data;',
        }
      }
    );
  }

  public response(form: any): Observable<any> {
    return this.http.post<any>(
      `${apiUrl}send/message/response`,
      form,
      {
        headers: {
          'enctype': 'multipart/form-data;',
        }
      }
    );
  }

  public setTypesMessage(messages: TypeMessages[]) {
    this.typeMessage = messages;
  }

  public get(route: string): Observable<any> {
    return this.http.get<any>(`${apiUrl}${route}`, this.httpOptions);
  }

  public delete(route: string): Observable<any> {
    return this.http.delete<any>(`${apiUrl}${route}`, this.httpOptions);
  }
}
