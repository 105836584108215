import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ScholarshipStudentsService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  index(id?): Observable<any> {
    let query = '';
    if ( id != 'null'){
      query = '?branch_office=' + id;
    }
    return this.http
      .get<any>(apiUrl + 'scholarship-student' + query, this.httpOptions);
  }

  create(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'scholarship-student', provider, this.httpOptions);
  }

  update(provider: any, id: any): Observable<any> {
    return this.http
      .put<any>(apiUrl + 'scholarship-student/' + id, provider, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http
      .get<any>(apiUrl + 'scholarship-student/' + id, this.httpOptions);
  }

}
