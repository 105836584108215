import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  public fileIsImage(fileName: string) {
    const extensions = ['jpg', 'jpeg', 'png'];
    return extensions.includes(fileName.split('.').pop());
  }
}
