import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  token: string = 'api_token';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    params: new HttpParams()
  };

  constructor(private http: HttpClient) { }

  index(id, idEstudent): Observable<any> {
    this.httpOptions.params = this.httpOptions.params.delete('branch_office');
    this.httpOptions.params = this.httpOptions.params.delete('student_id');
    if (id != 'undefined') {
      this.httpOptions.params = this.httpOptions.params.set('branch_office', id);
    }
    if (idEstudent) {
      this.httpOptions.params = this.httpOptions.params.set('student_id', idEstudent);
    }
    return this.http.get<any>(`${apiUrl}messages`, this.httpOptions);
  }

  addMessage(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "messages", message, this.httpOptions);
  }
  show(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'messages/' + id, this.httpOptions);
  }

  response(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "message-responses", message, this.httpOptions);
  }

  addAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "messages/attachments/" + id, attaches, this.httpOptions);
  }

  responseAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "message-responses/attachments/" + id, attaches, this.httpOptions);
  }

}
