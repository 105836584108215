import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { PaymentsService } from 'src/app/services/services.index';

@Component({
  selector: 'app-data-pay',
  templateUrl: './data-pay.component.html',
  styleUrls: ['./data-pay.component.scss'],
})
export class DataPayComponent implements OnInit {
  paymentDetail = null;
  @Input() outflow: any;
  @Input() notConsultation?: boolean;
  subscription1: Subscription = new Subscription();

  constructor(
    private modalController: ModalController,
    private paymentsService: PaymentsService
  ) {}

  ngOnInit() {
    this.getInflow();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getInflow() {
    if (this.notConsultation) {
      this.paymentDetail = this.outflow;
      return;
    }

    this.subscription1 = this.paymentsService
      .show(this.outflow.payment_id)
      .subscribe({
        next: (res) => {
          this.paymentDetail = res;
          console.log(res);
        },
        error: (e) => console.log(e),
      });
  }

  getPeriod() {
    if (this.paymentDetail.period_to) {
      return `${this.paymentDetail.period_from} - ${this.paymentDetail.period_to}`;
    }

    return this.paymentDetail?.period_from;
  }
}
