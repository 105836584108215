import { Component, OnInit, Input } from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UsersService } from '../../services/users/users.service';
import { ConstantsService } from '../../services/constants.service';
import { Storage } from '@ionic/storage';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  userId;
  user: any;
  osForm: FormGroup;
  constructor(
    private menu: MenuController,
    private router: Router,
    private usersService: UsersService,
    private constantsService: ConstantsService,
    private alertController: AlertController,
    private storage: Storage,
    private fb: FormBuilder,
  ) {
    this.osForm = this.fb.group(
      {
        one_signal_user_id: null,
        _method: 'PUT'
      }
    );
  }

  ngOnInit() { }

  ionViewDidEnter() { }

  closeMenu() {
    console.log('cerrar');
    this.menu.toggle();
  }

  async logout() {
    this.userId = this.constantsService.getUserId();
    await (await this.usersService.putOnesignalId(this.userId, this.osForm.value)).subscribe(res => { console.log('onesignal borrado'); });
    this.clearSessionData();
  }

  clearSessionData() {
    let us = localStorage.getItem('us');
    let pass = localStorage.getItem('pass');
    let oneSignalId = localStorage.getItem('oneSignalId');
    localStorage.clear();
    this.storage.remove('api_token');
    this.constantsService.token
    if (us && pass) {
      localStorage.setItem('us', us);
      localStorage.setItem('pass', pass);
    }
    localStorage.setItem('oneSignalId', oneSignalId);
    this.menu.toggle();
    this.router.navigate(["/start"]);
  }

  async logoutErrorAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error',
      message: 'Ocurrió un error al cerrar sesión, por favor intente nuevamente',
      buttons: ['OK']
    });
    await alert.present();
  }

  onMenuOpen() {
    this.user = this.constantsService.getUserFullName();
  }
}
