import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }

  add(provider: any): Observable<any> {
    return this.http.post<any>(apiUrl + 'payments', provider, this.httpOptions);
  }

  index(id): Observable<any> {
    const query = '?user_id=' + id;
    return this.http.get<Observable<any>>(apiUrl + 'payments' + query, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'payments/' + id, this.httpOptions);
  }

  update(id: string, provider: any): Observable<any> {
    return this.http.put<any>(apiUrl + 'payments/' + id,  provider, this.httpOptions);
  }
}
