import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRealStudent'
})
export class FilterRealStudentPipe implements PipeTransform {

  transform(array: any[], text: string): any[] {

    if(text === ''){
      return array;
    }
  //  console.log(array);
    text = text.toLowerCase();
  
    return array.filter(function(item){
    
      return item.person.first_name.toLowerCase().includes(text) ||
      item.person.first_name.toLowerCase().includes(text)
  });

   

  }
}
