import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }


  index(id): Observable<any> {
    let query = '';
    if ( id !== 'null'){
      query = '?branch_office=' + id;
    }
    return this.http.get<any>(apiUrl + 'providers', this.httpOptions);
  }

  add(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'providers', provider, this.httpOptions);
  }

  update(provider: any, id: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'providers/' + id, provider, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'providers/' + id, this.httpOptions);
  }
}
