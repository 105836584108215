import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PayoutCategoriesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }


  index(): Observable<any> {
    return this.http.get<any>(apiUrl + 'payout-categories', this.httpOptions);
  }

  add(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'payout-categories', provider, this.httpOptions);
  }

  update(provider: any, id: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'payout-categories/' + id, provider, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'payout-categories/' + id , this.httpOptions);
  }
}
