import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HomeworksService {

	httpOptions = {
	  headers: new HttpHeaders({
	    'Content-Type': 'application/json',
	   })
	};

  constructor( private http:HttpClient ) { }

	index(id): Observable<any> {
	  let query='';
	  console.log(id);
	  if(id!='null'){
	    query='?branch_office='+id;
	  }
	  return this.http.get<any>(apiUrl+'homeworks'+query,this.httpOptions);
	}

	add(homework: any): Observable<any> {
	  return this.http.post<any>(apiUrl+ "homeworks", homework, this.httpOptions);
	}

	show(id): Observable<any> {
	  return this.http.get<any>(apiUrl+'homeworks/'+id,this.httpOptions);
	}

	response(homework: any): Observable<any> {
	  return this.http.post<any>(apiUrl+ "homework-responses", homework, this.httpOptions);
	}

	addAttachment(attaches: any,id: any): Observable<any> {
	  return this.http.post<any>(apiUrl+ "homeworks/attachments/"+id, attaches, this.httpOptions);
	}
	
	responseAttachment(attaches: any,id: any):  Observable<any> {
	    return   this.http.post<any>(apiUrl+ "homework-responses/attachments/"+id, attaches, this.httpOptions);
	}
}
