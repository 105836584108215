import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentMethods } from 'src/app/interfaces';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }


  index(): Observable<PaymentMethods[]> {
    return this.http.get<PaymentMethods[]>(apiUrl + 'payment-methods', this.httpOptions);
  }

  add(provider: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'payment-methods', provider, this.httpOptions);
  }

  update(provider: any, id: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'payment-methods/' + id, provider, this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'payment-methods/' + id, this.httpOptions);
  }
}
