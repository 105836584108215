export class CONSTANTS {
  public static errorMessage = {
    FIELD_REQUIRED: 'Campo requerido',
    EMAIL_REQUIRED: 'Ingresa un correo valido',
    TEL_REQUIRED: 'Ingresa un numero de telefono valido',
    PASSWORD_NO_EQUAL: 'Deben coincidir las contraseñas',
    FIELDS_REQUIRED: 'Campos obligatorios',
    EMAIL_EMPTY: 'Por favor, añada una direccion de email',
    PASSWORD_EMPTY: 'Por favor, introduzca su contraseña',
    MIN_5_CHARACTERS: 'El campo debe tener mínimo 5 caracteres',
    MIN_8_CHARACTERS: 'El campo debe tener mínimo 8 caracteres',
    MAX_45_CHARACTERS: 'El campo debe tener máximo 45 caracteres',
    MAX_CHARACTERS: 'Ha sobrepasado el límite de caracteres permitidos',
    CHARACTERS_18: 'El campo debe tener 18 caracteres',
    FROM_NOT_LT_TO: "La fecha 'desde' no puede ser mayor a la fecha 'hasta'",
    FILE_REQUIRED: 'Debe cargar un archivo',
  };
}
