import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'create-user',
    loadChildren: () => import('./pages/administration/users/create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'taps',
    loadChildren: () => import('./pages/taps/taps.module').then( m => m.TapsPageModule)
  },
  {
    path: 'administration',
    loadChildren: () => import('./pages/administration/administration.module').then( m => m.AdministrationPageModule)
  },
 
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups.module').then( m => m.GroupsPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'communications',
    loadChildren: () => import('./pages/communications/communications.module').then( m => m.CommunicationsPageModule)
  },
  {
    path: 'grades',
    loadChildren: () => import('./pages/administration/grades/grades/grades.module').then( m => m.GradesPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'terminos',
    loadChildren: () => import('./pages/temrminos/temrminos.module').then( m => m.TemrminosPageModule)
  },
  {
    path: 'qr-codes',
    loadChildren: () => import('./pages/qr-codes/qr-codes.module').then( m => m.QrCodesPageModule)
  },  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
