import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-message-gallery',
  templateUrl: './message-gallery.component.html',
  styleUrls: ['./message-gallery.component.scss'],
})
export class MessageGalleryComponent implements OnInit {
  @Input() attaches: any;
  constructor() { }

  ngOnInit() {} 

}
