import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class BranchMessagesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(private http: HttpClient) { }

  addBranchMessage(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "branch-messages", message, this.httpOptions);
  }
  index(id): Observable<any> {
    let query = '';
    if (id) {
      query = '?student_id=' + id;
    }
    return this.http.get<any>(apiUrl + 'branch-messages' + query, this.httpOptions);
  }

  show(id): Observable<any> {
    return this.http.get<any>(apiUrl + 'branch-messages/' + id, this.httpOptions);
  }

  response(message: any): Observable<any> {
    return this.http.post<any>(apiUrl + "branch-responses", message, this.httpOptions);
  }

  addAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "branch-messages/attachments/" + id, attaches, this.httpOptions);
  }

  responseAttachment(attaches: any, id: any): Observable<any> {
    return this.http.post<any>(apiUrl + "branch-responses/attachments/" + id, attaches, this.httpOptions);
  }
}
