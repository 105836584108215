import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { finalize } from 'rxjs';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  public data = [];
  public loading = true;
  public route = '';
  constructor(
    private navParams: NavParams,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.route = this.navParams.data.isResponse
      ? `show/views/response/${this.navParams.data.id}`
      : `show/views/${this.navParams.data.id}`;

    this.getShows();
  }

  private getShows() {
    this.messageService
      .get(this.route)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((res) => {
        this.data = res.data;
      });
  }
}
