import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RelationshipService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor( private http: HttpClient ) { }

  index(): Observable<any> {
    return this.http.get<any>(apiUrl+'relationships',this.httpOptions);
  }

}
 