import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IPagination } from 'src/app/interfaces/pagination';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  token: string = 'api_token';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private http: HttpClient) {}

  index(pagination?: IPagination): Observable<any> {
    return this.http.get<any>(
      `${apiUrl}events${
        pagination ? `?page=${pagination.page}&limit=${pagination.limit}` : ''
      }`,
      this.httpOptions
    );
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl + 'events/' + id, this.httpOptions);
  }

  addStudent(student: any): Observable<any> {
    return this.http.post<any>(apiUrl + 'events', student, this.httpOptions);
  }

  enrollGroup(student: any, id: any): Observable<any> {
    return this.http.post<any>(
      apiUrl + 'events/' + id + '/enroll',
      student,
      this.httpOptions
    );
  }

  editevent(event: any, id): Observable<any> {
    return this.http.post<any>(
      apiUrl + 'events/' + id,
      event,
      this.httpOptions
    );
  }

  attach(event: any, id): Observable<any> {
    return this.http.post<any>(
      `${apiUrl}events/${id}/attachments/attach`,
      event,
      this.httpOptions
    );
  }
}
