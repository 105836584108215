import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User, LoginData } from '../../interfaces/interfaces';
import {Observable } from 'rxjs';
import { UserData } from '../../interfaces/userData';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  token = 'api_token';

  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      enctype: 'multipart/form-data;',
     })
  };

  constructor( private http: HttpClient ) { }

  login(loginData: LoginData): Observable<any> {
    return this.http.post<any>(apiUrl + 'login', loginData, this.httpOptions);
  }

  logout(): Observable<any>{
    return this.http.get<any>(apiUrl + 'logout', this.httpOptions);
  }

  apiTokenValidator() {
    return this.http.get<any>(apiUrl + 'apiTokenValidator', this.httpOptions);
  }

  index(rol: string, branchId: any): Observable<User[]> {
    let query = '';
    if (branchId !== 'null'){
      query = '&branch_office=' + branchId;
    }
    return this.http.get<User[]>(apiUrl + 'user?role_name=' + rol + query, this.httpOptions);
  }

  show(id: number): Observable<UserData>{
    return this.http.get<UserData>(apiUrl + 'user/' + id, this.httpOptions);
  }

  update(user: any, id){
    return this.http
      .post<any>(apiUrl + 'user/' + id, user, this.httpOptions);
  }

  getRoles(): Observable<any>{

    return this.http.get<any>(apiUrl + 'role', this.httpOptions);
  }

  addUser(user: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'register', user, this.httpOptions);
  }

  addPeople(person: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'people', person, this.httpOptions);
  }

  addEmployee(employee: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'employee', employee, this.httpOptions);
  }

  addParent(parent: any): Observable<any> {
    return this.http
      .post<any>(apiUrl + 'parent', parent, this.httpOptions);
  }

  showPerson(idPerson: any): Observable<any>{
    return this.http.get<any>(apiUrl + 'people/' + idPerson, this.httpOptions);
  }

  showParent(idParent: any): Observable<any>{
    return this.http.get<any>(apiUrl + 'parent/' + idParent, this.httpOptions);
  }

  listParent(branchId){
    let query = '';
    if (branchId !== 'null'){
      query = '&branch_office=' + branchId;
    }
    return this.http.get<any[]>(apiUrl + 'parent' + query, this.httpOptions);
  }

  updateParent(parent: any, id){
    return this.http
      .post<any>(apiUrl + 'parent/' + id, parent, this.httpOptions);
  }

  updatePeople(People: any, id){
    return this.http
      .post<any>(apiUrl + 'people/' + id, People, this.httpOptions);
  }

  deleteUser(idUser){
    return this.http
    .delete<any>(apiUrl + 'user/' + idUser, this.httpOptions);
  }

  QRlogin(loginData: any ): Observable<any>{
    return this.http.post<any>(apiUrl + 'qrlogin', loginData, this.httpOptions);
  }

  async putOnesignalId(userId: number, OsId: any) {
     return this.http.put<any>(apiUrl + 'user/onesignal/' + userId, OsId, this.httpOptions);
  }

  QRimage(data: any): Observable<any>{
    return this.http.post<any>(apiUrl + 'qrimage', data, this.httpOptions);
  }

  enableUser(stundetid){
    return this.http
    .get<any>(apiUrl + 'user-enable/' + stundetid, this.httpOptions);
  }

}
