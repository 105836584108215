import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Employees } from 'src/app/interfaces';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json'
     })
  };
  constructor( private http: HttpClient ) { }

  index(): Observable<Employees[]>{
    return this.http.get<Employees[]>(apiUrl + 'employee', this.httpOptions);
  }
  
  getFrecuencia(){
    return this.http.get(apiUrl + 'frecuencias-pago', this.httpOptions);
  }
  
  getEmp(id: string){
    return this.http.get<any>(apiUrl + 'employee/' + id, this.httpOptions);
  }

  update(employee: any, id){
    return this.http.post<any>(apiUrl + 'employee/' + id, employee, this.httpOptions);
  }

  getListColarborador(id){
    let query = '';
    if ( id != 'null'){
      query = '?branch_office=' + id;
    }
    return this.http.get(apiUrl + 'colaborador', this.httpOptions);
  }

  setColarborador(collaborator) {
    return this.http.post(apiUrl + 'colaborador', collaborator, this.httpOptions);
  }

  getColarborador(id){
    return this.http.get(apiUrl + 'colaborador/' + id, this.httpOptions);
  }

  updateColaborador(id, employee: any){
    return this.http.put<any>(apiUrl + 'colaborador/' + id, employee, this.httpOptions);
  }
}
