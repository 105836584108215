import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BranchOffice } from 'src/app/interfaces';
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }

  index(): Observable<BranchOffice[]> {
    return this.http.get<BranchOffice[]>(apiUrl + 'branch-office', this.httpOptions);
  }

  show(id): Observable<any[]> {
    return this.http.get<any[]>(apiUrl + 'branch-office/' + id, this.httpOptions);
  }

  addStudent(student: any): Observable<any> {
    return this.http
      .post<any>(apiUrl +  'branch-office', student, this.httpOptions);
  }

  updateBranch(student: any, id): Observable<any> {
    return this.http
      .post<any>(apiUrl +  'branch-office/' + id, student, this.httpOptions);
  }
}
