import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User, LoginData, People } from '../../interfaces/interfaces';
import { throwError, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  
  token:string = 'api_token'   ;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  
  constructor( private http: HttpClient ) { }

  show(id: number): Observable<People>{
    return this.http.get<People>(apiUrl+'people/'+id, this.httpOptions);
  }

  credentialDownload(studentId, personId): Observable<any> {
    let formData = new FormData();

    const form = {
      student_id:studentId,
      person_id:personId
    }
    return this.http.post(apiUrl+'credential', form, this.httpOptions);
  }

  credentialDownloadAll(studentId): Observable<any> {
    let formData = new FormData();

    const form = {
      student_id:studentId,
    }
    return this.http.post(apiUrl+'credential-all', form, this.httpOptions);
  }
}
