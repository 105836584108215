import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateRange } from 'src/app/models';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  form: FormGroup;
  @Output() range = new EventEmitter<DateRange>();
  dateRange: DateRange;
  aviso = true;

  constructor(
    private formBuild: FormBuilder,
  ) { }

  ngOnInit() {
    this.getSessionStorage();
  }

  private buildForm() {
    this.form = this.formBuild.group({
      desde: [this.dateRange.desde, Validators.required],
      hasta: [this.dateRange.hasta, Validators.required],
    });
    this.form.valueChanges.subscribe(data => this.createDate(data));
  }

  createDate(d: DateRange) {
    this.dateRange = d;
    if (this.dateRange.desde && this.dateRange.hasta) {
      this.range.emit(this.dateRange);
      this.aviso = false;
      this.saveSessionStorage(this.dateRange);
    }
  }

  saveSessionStorage(range: DateRange) {
    sessionStorage.setItem('rangeReports', JSON.stringify(range));
  }

  getSessionStorage() {
    const range = JSON.parse(sessionStorage.getItem('rangeReports'));
    this.dateRange = {
      desde: range?.desde || '',
      hasta: range?.hasta || '',
    };
    this.buildForm();
    if (range) {
      this.aviso = false;
      setTimeout(() => {
        this.range.emit(this.dateRange);
      }, 1000);
    }
  }

}
