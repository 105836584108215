import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  getReport(url: string, params) {
    const options = { params };
    return this.http.get<any>(url, options);
  }

  getDetail(params: any) {
    const options = { params };
    return this.http.get<any>(
      `${environment.apiUrl}report-egresos-category`,
      options
    );
  }
}
