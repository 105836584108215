import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productsFilter'
})
export class ProductsFilterPipe implements PipeTransform {

  transform(values: any, arg: string): any[] {
    if(arg == '') return values;
    if(arg.length < 2) return values;
    const result = [];
    for(const value of values){
      if(value.name.toLowerCase().indexOf(arg.toLowerCase()) > -1){
        result.push(value);
      }
    }
    return result;
  }

}
