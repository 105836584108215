import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const apiUrl=environment.apiUrl;

@Injectable({ 
  providedIn: 'root'
})
export class GroupService { 


  token:string = 'api_token'   ;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor( private http: HttpClient ) { }

  index(id): Observable<any> {
    let query='';
    if(id!='null'){
      query='?branch_office='+id;
      
    }
    return this.http.get<any>(apiUrl+'group'+query,this.httpOptions);
  }

  show(id: any): Observable<any> {
    return this.http.get<any>(apiUrl+'group/'+id,this.httpOptions).pipe(map(data =>{
      return data;
    }));
  }

  updateGroup(group,id): Observable<any> {
    return this.http.post<any>(apiUrl+'group/'+id,group,this.httpOptions).pipe(map(data =>{
      return data;
    }));
  }

  addStudent(student: any): Observable<any> {
    return this.http
      .post<any>(apiUrl+ "group", student, this.httpOptions);
  }
}
