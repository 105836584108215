import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
const apiUrl=environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class GeneralInfoService {
	
	httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };
  constructor(  private http: HttpClient  ) { }

  index(): Observable<any> {
    return this.http.get<any>(apiUrl+'general-info',this.httpOptions);
  }
  show(id): Observable<any> {
    return this.http.get<any>(apiUrl+'general-info/'+id,this.httpOptions);
  }
  store(generalInfo: any): Observable<any> {
    return this.http.post<any>(apiUrl+'general-info', generalInfo, this.httpOptions);
  }
  delete(generalInfo: any,id): Observable<any> {
    return this.http.post<any>(apiUrl+'general-info/'+id, generalInfo, this.httpOptions);
  }
  update(generalInfo: any,id){
    return this.http
      .post<any>(apiUrl+ "general-info/"+id, generalInfo, this.httpOptions);
  } 
  enable(id) : Observable<any> {
    return this.http.get<any>(apiUrl+'general-info/'+id, this.httpOptions);
  }

}
