import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const apiUrl=environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class EventSeenByService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
     })
  };

  constructor( private http: HttpClient ) { }

  Oversee(view): Observable<any> {
    console.log(view);
    return this.http.post<any>(apiUrl+'eventseen',JSON.stringify(view),this.httpOptions);
  }
}
