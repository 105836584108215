import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormControlName } from '@angular/forms';
import { CONSTANTS } from 'src/app/app.constant';

@Component({
  selector: 'app-validation-messages',
  templateUrl: './validation-messages.component.html',
  styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent implements OnInit {
  @Input() formControl?:FormControl;
  @Input() hasErrors?;
  errorMessages = CONSTANTS.errorMessage;
  
  constructor() { }

  ngOnInit() {}

}
