import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverComponent } from '../components/popover/popover.component';
import { PopoverGroupsComponent } from '../components/popover-groups/popover-groups.component';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  constructor(public popoverController: PopoverController) {}

  async presentPopover(ev: any, id: number, isResponse = false) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { id, isResponse },
    });
    return await popover.present();
  }

  async presentCounterGroups(ev: any, data: any) {
    const popover = await this.popoverController.create({
      component: PopoverGroupsComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      mode: 'ios',
      componentProps: { list: data },
    });
    return await popover.present();
  }
}
