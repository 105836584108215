import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'backend-error',
  templateUrl: './backend-error.component.html',
  styleUrls: ['./backend-error.component.scss'],
})
export class BackendErrorComponent implements OnInit {

  @Input() status: any;
  message: string;
  title: string;

  constructor() { }

  ngOnInit() {
    switch (this.status) {
      case 0:
        this.title = 'Error de Conexión';
        this.message = "Ha ocurrido un error de conexión, verifique que esta conectado a internet y que su conexión sea estable";
        break;
      case 404:
        this.title = 'Error 404';
        this.message = "El recurso al que intentas acceder no existe en el servidor";
        break;
      case 500:
        this.title = 'Error en el servidor';
        this.message = 'Ha ocurrio un error en el servidor, por favor intente nuevamente mas tarde';

    }
  }

}
